import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Pagination`}</h1>
    <p>{`Pagination breaks related content or data into
multiple pages and allows a client to navigate through them.`}</p>
    <ComponentPreview componentName="pagination--default-story" hasReact hasAngular hasHTML titleAttr="Pagination Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use Pagination when splitting related content or a
large set of data into smaller chunks across several
pages will make the content easier to consume.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Pagination only includes navigation elements for paging
and does not manipulate data or control the display of a
data grid; this control is used only to provide navigation
and to indicate the current page and number of pages.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Default`}</h3>
    <p>{`The Default variant displays back and forward Icon
chevrons as well as page numbers for each page.
If there are more than 3 pages, ellipses (…) are used
to condense the component and make it more readable
while still showing the first, last, and current pages.`}</p>
    <ComponentPreview componentName="pagination--default-story" hasReact hasAngular hasHTML titleAttr="Default Pagination Example" mdxType="ComponentPreview" />
    <h3>{`Pager`}</h3>
    <p>{`The Pager variant does not include page numbers and only
includes Icon chevrons for navigating forwards and backwards
between pages. Pager should be used when you want the client
to only navigate in sequential order.`}</p>
    <ComponentPreview componentName="pagination--pager" hasReact hasAngular hasHTML titleAttr="Pager Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Pagination must have Icon chevrons for incrementally
navigating forward or backward between pages.`}</li>
      <li parentName="ul">{`Default Pagination must have page numbers for
navigating to specific pages`}</li>
      <li parentName="ul">{`Pagination must include an ellipsis (…) indicating
hidden pages when there are more than 3 pages.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Users can navigate to the control using the keyboard
and are able to activate the Icon chevrons and page
numbers using the ENTER or SPACE keys.`}</li>
      <li parentName="ul">{`The component is correctly identified and interpreted
by screen reader technology.`}</li>
      <li parentName="ul">{`Each page number is correctly identified and interpreted
by screen reader technology.`}</li>
      <li parentName="ul">{`The current page number includes the `}<inlineCode parentName="li">{`aria-current`}</inlineCode>{`
attribute.`}</li>
      <li parentName="ul">{`The Icon chevrons are correctly identified by screen
reader technology.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      